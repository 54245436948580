import { useState, useEffect } from "react";
import { SurveyState } from "./surveyState";

export function Outro({ state }: { state: SurveyState }) {
  const [dataSent, setDataSent] = useState(false);

  useEffect(() => {
    if (dataSent) return;
    setDataSent(true);
    const http = new XMLHttpRequest();
    http.open("POST", "https://rating.corinnaj.me/upload.php");
    http.setRequestHeader("Content-type", "application/json");
    http.onreadystatechange = function () {
      if (http.readyState === XMLHttpRequest.DONE && http.status === 200) {
        // successfully sent, delete local storage
        localStorage.clear();
      }
    };
    http.send(JSON.stringify(state));
  }, [dataSent, state]);

  return (
    <div className="max-w-[700px] items-center flex flex-col mx-auto">
      <h1>Thanks for completing the ratings! </h1>
      <p>
        In order to receive your payment, please copy the code displayed below
        in MTurk:
      </p>
      <p className="font-bold">{state.id}</p>
    </div>
  );
}
