import { useState } from "react";
import { DemographicsSurvey } from "./DemographicsSurvey";
import { RatingPage } from "./RatingAnswers";
import { SurveyState } from "./surveyState";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { answers, suggesters } from "./data";
import { Intro } from "./Intro";
import { Outro } from "./Outro";
import { LinearProgress } from "@mui/material";

function shuffleArray(array: Array<any>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function ProgressBar({ page }: { page: number }) {
  const pageCount = 11;
  return (
    <>
      <LinearProgress
        className="!h-2"
        variant="determinate"
        value={(page / pageCount) * 100}
      />
      {page < pageCount && (
        <p className="pr-4 pt-2 text-end">
          {pageCount - page} page
          {pageCount - page !== 1 ? "s" : ""} to go
        </p>
      )}
    </>
  );
}

function App() {
  const [page, setPage] = useState<number>(() =>
    parseInt(localStorage.getItem("page") ?? "0")
  );
  const [state, setState] = useState<SurveyState>(() => {
    function initAnswers() {
      let selectedAnswers = answers.filter((a) => {
        const suggester = suggesters.find((s) => s.userId === a.userId);
        return suggester?.batch === 1;
      });
      shuffleArray(selectedAnswers);
      selectedAnswers = selectedAnswers.map((a, index) => {
        return { ...a, orderIndex: index, userAnswer: -1 };
      });
      return selectedAnswers;
    }

    const fallback = {
      id: null,
      demographics: {
        age: null,
        education: null,
        gender: null,
        englishProficiency: null,
        country: null,
        currentCountry: null,
        training: null,
      },
      batch: 1,
      ratedAnswers: initAnswers(),
    };

    const data = localStorage.getItem("surveyState");
    if (!data) return fallback;
    try {
      return JSON.parse(data);
    } catch {
      return fallback;
    }
  });

  useEffect(() => {
    if (state.id === null) setState((state) => ({ ...state, id: uuidv4() }));

    const urlParams = new URLSearchParams(window.location.search);
    const batch = urlParams.get("batch");
    if (state.batch === null && batch && [1, 2, 3].includes(parseInt(batch))) {
      setState((state) => ({ ...state, batch: parseInt(batch) as 1 | 2 | 3 }));
    }

    window.localStorage.setItem("surveyState", JSON.stringify(state));
  }, [setState, state]);

  function advance() {
    setPage((page) => page + 1);
    window.scrollTo(0, 0);
    window.localStorage.setItem("surveyState", JSON.stringify(state));
    window.localStorage.setItem("page", page.toString());
  }

  return (
    <>
      <ProgressBar page={page} />
      {page === 0 && <Intro advance={advance} />}
      {page > 0 && page < 10 && (
        <RatingPage
          state={state}
          setState={setState}
          index={page - 1}
          advance={advance}
        />
      )}
      {page === 10 && (
        <DemographicsSurvey
          state={state}
          setState={setState}
          advance={advance}
        />
      )}
      {page === 11 && <Outro state={state} />}
    </>
  );
}

export default App;
