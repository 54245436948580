export interface RatedAnswer {
  userId: string;
  suggestion: string;
  userAnswer?: number;
  orderIndex?: number;
}

export interface Suggester {
  userId: string;
  continent: "Asia" | "North America";
  batch: number;
}

export const suggesters: Suggester[] = [
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    continent: "Asia",
    batch: 1,
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    continent: "Asia",
    batch: 1,
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    continent: "Asia",
    batch: 1,
  },
  {
    userId: "171f8ef5-3dd7-47d4-aa78-89be4d91b03b",
    continent: "Asia",
    batch: 1,
  },
  {
    userId: "351be72b-e2f9-475c-a17b-0f9e8e40f7bb",
    continent: "Asia",
    batch: 1,
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    continent: "Asia",
    batch: 2,
  },
  {
    userId: "5e02cf8a-b0d7-4db8-8bf6-b6022845cbc7",
    continent: "Asia",
    batch: 2,
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    continent: "Asia",
    batch: 2,
  },
  {
    userId: "632f57d4-5235-4518-a15c-f0ce096b6b9d",
    continent: "Asia",
    batch: 2,
  },
  {
    userId: "75b1e446-7092-4688-ba6f-5a3578b4399e",
    continent: "Asia",
    batch: 3,
  },
  {
    userId: "97793613-0f48-4755-a9e6-1f1e0efa02c4",
    continent: "Asia",
    batch: 1,
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    continent: "Asia",
    batch: 3,
  },
  {
    userId: "9a529bee-43a1-47ef-ad9a-154b1cd32252",
    continent: "Asia",
    batch: 2,
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    continent: "Asia",
    batch: 3,
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    continent: "Asia",
    batch: 3,
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    batch: 3,
    continent: "Asia",
  },
  {
    userId: "d62028ea-8408-49c4-84c0-511891a3a069",
    continent: "Asia",
    batch: 3,
  },

  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    continent: "North America",
    batch: 1,
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    continent: "North America",
    batch: 1,
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    continent: "North America",
    batch: 1,
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    continent: "North America",
    batch: 1,
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    continent: "North America",
    batch: 2,
  },
  {
    userId: "5a28ddfb-a940-4fe8-ae19-7365359a808d",
    continent: "North America",
    batch: 3,
  },
  {
    userId: "6a3b9e59-41c5-4694-baa7-6b208bac9566",
    continent: "North America",
    batch: 2,
  },
  {
    userId: "6bb60910-085f-4e1d-8f7f-7b11a37e13d7",
    continent: "North America",
    batch: 2,
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    continent: "North America",
    batch: 2,
  },
  {
    userId: "8fcc9cb7-0e70-4d43-9766-153919fe7be9",
    continent: "North America",
    batch: 2,
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    continent: "North America",
    batch: 3,
  },
  {
    userId: "a0fdaa87-d0c0-481a-aa06-88b76d4c3e4c",
    continent: "North America",
    batch: 1,
  },
  {
    userId: "bda82bbb-115d-45d3-a6ce-5fa58a2f7cc3",
    continent: "North America",
    batch: 2,
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    continent: "North America",
    batch: 3,
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    continent: "North America",
    batch: 3,
  },
  {
    userId: "f2f9a3da-8626-4e0c-8b57-edf7da731eb9",
    continent: "North America",
    batch: 3,
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    continent: "North America",
    batch: 3,
  },
];

export const answers: RatedAnswer[] = [
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    suggestion: "Brick by Brick",
  },
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    suggestion: "Weigh Down a Tarp over Firewood",
  },
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    suggestion: "Create a Tiered Planter",
  },
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    suggestion: "Craft a Tea-Light Holder",
  },
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    suggestion: "Secure a Downspout",
  },
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    suggestion: "Store Shop Pencils",
  },
  {
    userId: "10efb1c6-bfd9-4a02-9767-d254c81a6355",
    suggestion: "Improve The Way a Container Drains",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "fence for a garden",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "to broke something",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "to build kennel",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "to hit some one",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "to make a furnace",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "to",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "tooth paste",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "used it as a color powder",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "to build a house",
  },
  {
    userId: "1131e677-99af-452e-a1b7-4ec7a4a24384",
    suggestion: "used as a door stopper",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "BENCH",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "BLOCKER",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "BROKE",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "CEILING",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "COMPOUND WALL",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "FLOOR",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "HOME",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "OFFICE",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "PARK",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "PLAY",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "BUILDING",
  },
  {
    userId: "166076ca-fa8a-440e-ab6c-319a38714f08",
    suggestion: "WALL",
  },
  {
    userId: "171f8ef5-3dd7-47d4-aa78-89be4d91b03b",
    suggestion: "building a wall",
  },
  {
    userId: "171f8ef5-3dd7-47d4-aa78-89be4d91b03b",
    suggestion: "crush for filler material",
  },
  {
    userId: "171f8ef5-3dd7-47d4-aa78-89be4d91b03b",
    suggestion: "door stopper",
  },
  {
    userId: "171f8ef5-3dd7-47d4-aa78-89be4d91b03b",
    suggestion: "support for a parked car",
  },
  {
    userId: "171f8ef5-3dd7-47d4-aa78-89be4d91b03b",
    suggestion: "house",
  },
  {
    userId: "351be72b-e2f9-475c-a17b-0f9e8e40f7bb",
    suggestion: "to make house",
  },
  {
    userId: "351be72b-e2f9-475c-a17b-0f9e8e40f7bb",
    suggestion: "to build bunker",
  },
  {
    userId: "351be72b-e2f9-475c-a17b-0f9e8e40f7bb",
    suggestion: "to kill someone",
  },
  {
    userId: "351be72b-e2f9-475c-a17b-0f9e8e40f7bb",
    suggestion: "to make ladder",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "barricade",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "build wall",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "defense object",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "dumbell",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "etch something",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "footstool",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "hit someone with it",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "paper weight",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "pillow",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "practice karate",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "rub",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "sharpening knife",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "stack it up to make a tower",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "weights",
  },
  {
    userId: "5446e07a-d4e9-48a5-8205-5e528f8ba6bb",
    suggestion: "chopping block",
  },
  {
    userId: "5e02cf8a-b0d7-4db8-8bf6-b6022845cbc7",
    suggestion: "arches",
  },
  {
    userId: "5e02cf8a-b0d7-4db8-8bf6-b6022845cbc7",
    suggestion: "building home",
  },
  {
    userId: "5e02cf8a-b0d7-4db8-8bf6-b6022845cbc7",
    suggestion: "compound",
  },
  {
    userId: "5e02cf8a-b0d7-4db8-8bf6-b6022845cbc7",
    suggestion: "claddings",
  },
  {
    userId: "5e02cf8a-b0d7-4db8-8bf6-b6022845cbc7",
    suggestion: "construction",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "alter",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "break",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "cake",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "clay",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "data",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "fix",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "key",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "label",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "mountain",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "number",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "paste",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "riple",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "mud",
  },
  {
    userId: "5f6b64f5-e081-4761-845f-1c3e3dc81a5a",
    suggestion: "stone",
  },
  {
    userId: "632f57d4-5235-4518-a15c-f0ce096b6b9d",
    suggestion: "a handy-sized unit of building or paving material",
  },
  {
    userId: "75b1e446-7092-4688-ba6f-5a3578b4399e",
    suggestion: "Arches",
  },
  {
    userId: "75b1e446-7092-4688-ba6f-5a3578b4399e",
    suggestion: "Claddings",
  },
  {
    userId: "75b1e446-7092-4688-ba6f-5a3578b4399e",
    suggestion: "House",
  },
  {
    userId: "75b1e446-7092-4688-ba6f-5a3578b4399e",
    suggestion: "Wall",
  },
  {
    userId: "97793613-0f48-4755-a9e6-1f1e0efa02c4",
    suggestion: "school",
  },
  {
    userId: "97793613-0f48-4755-a9e6-1f1e0efa02c4",
    suggestion: "building",
  },
  {
    userId: "97793613-0f48-4755-a9e6-1f1e0efa02c4",
    suggestion: "house",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "hard drive",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "mobile phone",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "computer mouse",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "clay",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "book",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "box",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "shampoo bottle",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "photo frame",
  },
  {
    userId: "9a51d098-ab18-4457-9644-404ed2aaa19a",
    suggestion: "me",
  },
  {
    userId: "9a529bee-43a1-47ef-ad9a-154b1cd32252",
    suggestion: "build a house",
  },
  {
    userId: "9a529bee-43a1-47ef-ad9a-154b1cd32252",
    suggestion: "hit someone",
  },
  {
    userId: "9a529bee-43a1-47ef-ad9a-154b1cd32252",
    suggestion: "sit on a brick",
  },
  {
    userId: "9a529bee-43a1-47ef-ad9a-154b1cd32252",
    suggestion: "use as a paperweight",
  },
  {
    userId: "9a529bee-43a1-47ef-ad9a-154b1cd32252",
    suggestion: "use to break nuts",
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    suggestion: "garden path",
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    suggestion: "garden table",
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    suggestion: "khoa",
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    suggestion: "planter",
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    suggestion: "shlef",
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    suggestion: "Building",
  },
  {
    userId: "9d315983-f241-4acd-81ed-b7418e462685",
    suggestion: "gardening",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "dumbbell",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "ball",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "seat",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "fire pit",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "tooth powder",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "weights",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "bat",
  },
  {
    userId: "cb4fb352-b1a1-479f-848d-5fa0612ad58b",
    suggestion: "decoration",
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    suggestion: "Arm Fitness",
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    suggestion: "Blocking holes",
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    suggestion: "Break something",
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    suggestion: "Building House",
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    suggestion: "Getting Clay",
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    suggestion: "Stop the car from slope",
  },
  {
    userId: "cbc67a3e-e2b6-45d3-a7bb-191e4ddc691a",
    suggestion: "Used to clean teeth",
  },
  {
    userId: "d62028ea-8408-49c4-84c0-511891a3a069",
    suggestion: "bridge",
  },
  {
    userId: "d62028ea-8408-49c4-84c0-511891a3a069",
    suggestion: "house",
  },
  {
    userId: "d62028ea-8408-49c4-84c0-511891a3a069",
    suggestion: "pond",
  },
  {
    userId: "d62028ea-8408-49c4-84c0-511891a3a069",
    suggestion: "tank",
  },
  {
    userId: "d62028ea-8408-49c4-84c0-511891a3a069",
    suggestion: "building",
  },
  {
    userId: "d62028ea-8408-49c4-84c0-511891a3a069",
    suggestion: "wall",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "art decoration",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "birthday present",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "bottle opener",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "brainstorming ideas for a brick",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "can crusher",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "door stop",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "nut cracker",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "paper weight",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "prop in a youtube video",
  },
  {
    userId: "085c9c84-a190-4e60-96da-029f0780c630",
    suggestion: "prop up a crooked table",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Abrasive scrub brush",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Blunt Weapon",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Doorstop",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Hammer",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Paperweight",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Percussion instrument",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Projectile Weapon",
  },
  {
    userId: "109222ab-bafe-4123-92a3-815688b250fc",
    suggestion: "Stool",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "Break a window",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "a scale",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "artwork",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "build a playhouse",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "crumble up to make garden soil",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "measure an area",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "paper weight",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "save water in a toilet",
  },
  {
    userId: "2b783aff-9025-496a-9949-06dfd9f2f9ed",
    suggestion: "tie to shoes to make you taller",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "boat anchor",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "bookmark",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "bracelet",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "door stop",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "earring",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "fish hook",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "flower pot",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "grout cleaner",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "hook",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "nail cleaner",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "necklace",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "paper weight",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "step stool",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "toothpick",
  },
  {
    userId: "51688ed5-ca6e-4ce7-b167-62e0478ff8d0",
    suggestion: "window stop",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "bench",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "bookends",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "can opener",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "chair",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "currency",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "diving weight",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "gift",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "hat",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "juggling",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "sculpture",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "seat",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "seedling planter",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "shed",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "shoes",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "step stool",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "weapon",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "weight lifting",
  },
  {
    userId: "54acdfdf-96e6-4083-892b-2c1f17430889",
    suggestion: "window opener",
  },
  {
    userId: "5a28ddfb-a940-4fe8-ae19-7365359a808d",
    suggestion: "paper weight",
  },
  {
    userId: "5a28ddfb-a940-4fe8-ae19-7365359a808d",
    suggestion: "stand",
  },
  {
    userId: "5a28ddfb-a940-4fe8-ae19-7365359a808d",
    suggestion: "step",
  },
  {
    userId: "5a28ddfb-a940-4fe8-ae19-7365359a808d",
    suggestion: "weapon",
  },
  {
    userId: "6a3b9e59-41c5-4694-baa7-6b208bac9566",
    suggestion: "door stopper",
  },
  {
    userId: "6a3b9e59-41c5-4694-baa7-6b208bac9566",
    suggestion: "object to write with",
  },
  {
    userId: "6a3b9e59-41c5-4694-baa7-6b208bac9566",
    suggestion: "pretend it's   a doll",
  },
  {
    userId: "6a3b9e59-41c5-4694-baa7-6b208bac9566",
    suggestion: "weapon",
  },
  {
    userId: "6a3b9e59-41c5-4694-baa7-6b208bac9566",
    suggestion: "weight",
  },
  {
    userId: "6bb60910-085f-4e1d-8f7f-7b11a37e13d7",
    suggestion: "door stop",
  },
  {
    userId: "6bb60910-085f-4e1d-8f7f-7b11a37e13d7",
    suggestion: "paper weight",
  },
  {
    userId: "6bb60910-085f-4e1d-8f7f-7b11a37e13d7",
    suggestion: "pencil holder",
  },
  {
    userId: "6bb60910-085f-4e1d-8f7f-7b11a37e13d7",
    suggestion: "step",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "Chalk",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "building",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "door stopper",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "driveway",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "firepit",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "garden",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "hammer",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "house",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "road",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "sidewalk",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "step",
  },
  {
    userId: "7700a28e-a3eb-4e88-bf91-101ee34940ab",
    suggestion: "weapon",
  },
  {
    userId: "8fcc9cb7-0e70-4d43-9766-153919fe7be9",
    suggestion: "art project",
  },
  {
    userId: "8fcc9cb7-0e70-4d43-9766-153919fe7be9",
    suggestion: "building a fence",
  },
  {
    userId: "8fcc9cb7-0e70-4d43-9766-153919fe7be9",
    suggestion: "building a house",
  },
  {
    userId: "8fcc9cb7-0e70-4d43-9766-153919fe7be9",
    suggestion: "paper weight",
  },
  {
    userId: "8fcc9cb7-0e70-4d43-9766-153919fe7be9",
    suggestion: "weapon",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Crush, mix with water, and drink",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Baking surface",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Doorstop",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Freeze and put in drinking pitcher like an ice cube",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Hold down a tarp",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Slide along floor like a toy car",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Small table",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "Weapon",
  },
  {
    userId: "910ddc9f-a666-45b9-a59b-0a6adc17f496",
    suggestion: "lift a car wheel",
  },
  {
    userId: "a0fdaa87-d0c0-481a-aa06-88b76d4c3e4c",
    suggestion:
      "If a section of downspout has come unattached near the ground, reinsert it, then lay a brick on each side to stabilize it until you can screw it back in place.",
  },
  {
    userId: "a0fdaa87-d0c0-481a-aa06-88b76d4c3e4c",
    suggestion:
      "Stack two bricks in the bottom of a large terra-cotta pot (we used one 17 inches in diameter). Set a 10-inch diameter terra-cotta pot on the bricks, to terrace the plantings, and fill with soil.",
  },
  {
    userId: "a0fdaa87-d0c0-481a-aa06-88b76d4c3e4c",
    suggestion:
      "Using a hole saw with a 1½-inch carbide-tipped masonry bit, drill two holes about ½ inch into a brick, just deep enough for a standard tea light to rest. Place a candle in each opening. Bonus: Outdoors, the brick will also anchor a tablecloth on a windy day.",
  },
  {
    userId: "a0fdaa87-d0c0-481a-aa06-88b76d4c3e4c",
    suggestion:
      "Clay blocks have a way of sticking around long after a project is complete. Put them to use with these ideas",
  },
  {
    userId: "a0fdaa87-d0c0-481a-aa06-88b76d4c3e4c",
    suggestion:
      "Place a few bricks along the lower edge of a waterproof cover to keep your wood pile dry.",
  },
  {
    userId: "bda82bbb-115d-45d3-a6ce-5fa58a2f7cc3",
    suggestion: "garden decor",
  },
  {
    userId: "bda82bbb-115d-45d3-a6ce-5fa58a2f7cc3",
    suggestion: "paperweight",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "a bookend",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "bug killer",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "chicken press",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "cook an egg on it in the sun ",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "door stop",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "impromptu door opener (break the window)",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "pie weight",
  },
  {
    userId: "c1631c65-53f8-4c16-8a9f-e6ffaa3fa47e",
    suggestion: "weapon for a break in",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "break walnuts",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "chair",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "draw on sidewalk",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "hold papers down",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "keep dog from running away",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "keep from car rolling",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "name tag",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "pencil holder",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "stilts",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "swat flies",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "under broken leg chair",
  },
  {
    userId: "ea70b672-9b4e-4595-9f7d-06f1f705a183",
    suggestion: "weightlifting",
  },
  {
    userId: "f2f9a3da-8626-4e0c-8b57-edf7da731eb9",
    suggestion: "anchor",
  },
  {
    userId: "f2f9a3da-8626-4e0c-8b57-edf7da731eb9",
    suggestion: "monitor stand",
  },
  {
    userId: "f2f9a3da-8626-4e0c-8b57-edf7da731eb9",
    suggestion: "paper weight",
  },
  {
    userId: "f2f9a3da-8626-4e0c-8b57-edf7da731eb9",
    suggestion: "pillow",
  },
  {
    userId: "f2f9a3da-8626-4e0c-8b57-edf7da731eb9",
    suggestion: "step",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "broken up to make small projectiles",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "chipped into an arrow head",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "counter balance",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "ground up to make dust",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "heat up for sustained warmth by fire",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "paper weight",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "piece of a wall",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "prop for broken chair leg",
  },
  {
    userId: "fe144f25-3ef7-4166-b083-ec11d13c5a8a",
    suggestion: "weapon",
  },
];
