import React, { useState, Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import { SurveyState } from "./surveyState";
import { Age } from "./demographicWidgets.tsx/Age";
import { Education } from "./demographicWidgets.tsx/Education";
import { Gender } from "./demographicWidgets.tsx/Gender";
import { Country } from "./demographicWidgets.tsx/Country";
import { EnglishProficiency } from "./demographicWidgets.tsx/EnglishProficiency";
import { Training } from "./demographicWidgets.tsx/Training";
import { CurrentCountry } from "./demographicWidgets.tsx/CurrentCountry";

export function DemographicsSurvey({
  state,
  setState,
  advance,
}: {
  state: SurveyState;
  setState: Dispatch<SetStateAction<SurveyState>>;
  advance: () => void;
}) {
  const [unfilledVariables, setUnfilledVariables] = useState(true);

  const updateState = (newState: SurveyState) => {
    setState(newState);

    setUnfilledVariables(
      newState.demographics.age === null ||
        newState.demographics.education === null ||
        newState.demographics.gender === null ||
        newState.demographics.englishProficiency === null ||
        newState.demographics.country === null ||
        newState.demographics.country === "" ||
        newState.demographics.currentCountry === null ||
        newState.demographics.currentCountry === "" ||
        newState.demographics.training === null ||
        false
    );
  };

  return (
    <div className="flex flex-col max-w-[700px] m-auto [&>*]:!mt-8">
      <h1>Demographics</h1>
      <p>Thanks for completing the ratings! </p>
      <Age state={state} updateState={updateState} />
      <Gender state={state} updateState={updateState} />
      <Country state={state} updateState={updateState} />
      <CurrentCountry state={state} updateState={updateState} />
      <EnglishProficiency state={state} updateState={updateState} />
      <Education state={state} updateState={updateState} />
      <Training state={state} updateState={updateState} />
      <div>
        {unfilledVariables && (
          <p className="text-sm text-red-600 !-mb-3 mt-3 text-right">
            All fields must be filled before proceeding
          </p>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="!mr-0 !ml-auto !flex !my-4"
          disabled={unfilledVariables}
          onClick={advance}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
