import { Button } from "@mui/material";

export function Intro({ advance }: { advance: () => void }) {
  return (
    <div className="max-w-[700px] items-center flex flex-col mx-auto">
      <h1>Welcome to our Study</h1>
      <p className="pb-4">
        In this study, you will be presented with a list of answers that people
        gave in a creativity test. In this test, examinees were asked to think
        up <b>uncommon-creative uses for a brick</b>. For instance, if someone
        answers “use to build a house”, the idea seems rather uncreative. Bricks
        are normally used for building houses. So, the answer is common rather
        than uncommon-creative.
        <br></br>
        <br></br>
        In this study, please help us evaluate the answers from test-takers. You
        will see 9 blocks of 10 answers from the creativity test. Each answer is
        an idea of what one could do with a brick. To be creative, the answers
        should be unconventional, but still sensible or appropriate in light of
        the task. It should be things that one could do with a brick.
        <br></br>
        <br></br>
        The study should take around 15 minutes to complete. Upon completion,
        you will be provided with a code for the MTurk survey to receive your
        payment.
      </p>

      <Button variant="contained" onClick={advance}>
        Start
      </Button>
    </div>
  );
}
