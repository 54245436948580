import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  TrainingOptions,
  trainingQuestion,
  trainingOptions,
  SurveyState,
} from "../surveyState";

export function Training({
  state,
  updateState,
}: {
  state: SurveyState;
  updateState: (state: SurveyState) => void;
}) {
  useEffect(() => {
    if (state.demographics.training === null) {
      updateState({
        ...state,
        demographics: {
          ...state.demographics,
          training: {
            agriculture: false,
            history: false,
            architecture: false,
            business: false,
            computers: false,
            engineering: false,
            arts: false,
            health: false,
            law: false,
            languages: false,
            literature: false,
            maths: false,
            music: false,
            philosophy: false,
            public: false,
            sales: false,
            social: false,
            science: false,
            sports: false,
            teaching: false,
            tourism: false,
            other: "",
          },
        },
      });
    }
  }, [state, updateState]);

  function updateTraining(key: keyof TrainingOptions, checked: boolean) {
    if (state.demographics.training === null) return;
    updateState({
      ...state,
      demographics: {
        ...state.demographics,
        training: {
          ...state.demographics.training,
          [key]: checked,
        },
      },
    });
  }

  function updateOtherTraining(other: string) {
    if (state.demographics.training === null) return;
    updateState({
      ...state,
      demographics: {
        ...state.demographics,
        training: {
          ...state.demographics.training,
          other: other,
        },
      },
    });
  }

  if (state.demographics.training === null) return null;
  return (
    <FormControl component="fieldset" className={"formControl demographics"}>
      <FormLabel component="legend">{trainingQuestion}</FormLabel>
      {Object.entries(trainingOptions)
        .filter(([key, _]) => key !== "other")
        .map(([key, answer]) => {
          return (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={
                    state.demographics.training![
                      key as keyof TrainingOptions
                    ] as boolean
                  }
                  onChange={(_e, checked) =>
                    updateTraining(key as keyof TrainingOptions, checked)
                  }
                  name={key}
                />
              }
              label={answer}
            />
          );
        })}
      <TextField
        label={"Other"}
        onChange={(e) => updateOtherTraining(e.target.value)}
        value={state.demographics.training.other}
      />
    </FormControl>
  );
}
