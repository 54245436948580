import { Button, Checkbox, FormControlLabel, Slider } from "@mui/material";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { RatedAnswer } from "./data";
import { SurveyState } from "./surveyState";

export function RatingPage({
  state,
  setState,
  index,
  advance,
}: {
  state: SurveyState;
  setState: Dispatch<SetStateAction<SurveyState>>;
  index: number;
  advance: () => void;
}) {
  const [unfilledVariables, setUnfilledVariables] = useState(true);
  function updateAnswer(value: number, answer: RatedAnswer) {
    setState((state) => ({
      ...state,
      ratedAnswers: [
        ...state.ratedAnswers.slice(0, answer.orderIndex!),
        { ...answer, userAnswer: value },
        ...state.ratedAnswers.slice(
          answer.orderIndex! + 1,
          state.ratedAnswers.length
        ),
      ],
    }));
  }

  useEffect(() => {
    setUnfilledVariables(
      state.ratedAnswers
        .filter(
          (a) => a.orderIndex! >= 10 * index && a.orderIndex! <= index * 10 + 9
        )
        .some((a) => a.userAnswer === -1 || a.userAnswer === undefined)
    );
  }, [state.ratedAnswers, index]);

  return (
    <div className=" text-center max-w-[740px] mx-auto my-0 px-4">
      <h1>Rating Creativity of Answers</h1>
      <p>
        Remember, you are looking at ideas for how one could use a <b>brick.</b>
      </p>
      <p>
        Please indicate <b>how creative you find each idea</b>. Evaluate on a
        scale from 0 to 3, with the following meanings:
      </p>
      <ul className="font-bold">
        <li>0: This idea is not creative</li>
        <li>1: This idea is a little creative</li>
        <li>2: This idea is quite creative</li>
        <li>3: This idea is highly creative</li>
      </ul>
      <p>
        If you don’t understand an answer, tick the respective box on the side
        instead.
      </p>

      <div>
        {state.ratedAnswers
          .filter(
            (a) =>
              a.orderIndex! >= 10 * index && a.orderIndex! <= index * 10 + 9
          )
          .map((answer) => {
            return (
              <Answer
                key={answer.orderIndex}
                answer={answer}
                updateAnswer={updateAnswer}
              />
            );
          })}
      </div>
      {unfilledVariables && (
        <p className="text-sm text-red-600 -mb-3 mt-3 text-right">
          All fields must be filled before proceeding
        </p>
      )}
      <Button
        variant="contained"
        className="!mr-0 !ml-auto !flex !my-4"
        disabled={unfilledVariables}
        onClick={advance}
      >
        Next
      </Button>
    </div>
  );
}

function Answer({
  answer,
  updateAnswer,
}: {
  answer: RatedAnswer;
  updateAnswer: (value: number, answer: RatedAnswer) => void;
}) {
  const marks = [
    { value: -1 },
    {
      value: 0,
      label: (
        <p>
          0<br />
          not creative
        </p>
      ),
    },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    {
      value: 3,
      label: (
        <p>
          3<br />
          highly creative
        </p>
      ),
    },
  ];

  return (
    <div className="flex p-6 even:bg-gray-200">
      <b className="flex-1 max-w-[420px] mr-4 text-left my-auto">
        {answer.suggestion.toLowerCase()}
      </b>
      <Slider
        className="!w-[200px] !ml-6 !mr-8 select-none"
        aria-label="Originality"
        color={answer.userAnswer !== -1 ? "primary" : "secondary"}
        valueLabelDisplay="off"
        step={1}
        marks={marks}
        track={false}
        min={-1}
        max={3}
        value={answer.userAnswer}
        disabled={answer.userAnswer === -2}
        onChange={(_, value) => updateAnswer(value as number, answer)}
      />
      <FormControlLabel
        className="select-none"
        label="unclear"
        control={
          <Checkbox
            checked={answer.userAnswer === -2}
            onChange={(_, checked) => updateAnswer(checked ? -2 : -1, answer)}
          />
        }
      />
    </div>
  );
}
